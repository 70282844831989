<template>
	<div class="page container">
		<v2-back-btn :route="`/browse-courses`" />
		<div class="page__info">
			<div class="page__info--title">
				Checkout error
			</div>
		</div>
		<p>There was a problem completing your checkout.</p>
		<V2Button
			size="xl"
			:to="`/browse-courses/${getCourseId}`"
			fit
		>
			Try again
		</V2Button>
	</div>
</template>

<script>
	import V2Button           from '@/components/v2/ui/V2Button';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';

	export default {
		metaInfo: {
			title: 'Checkout error'
		},
		layout: 'v2default',
		components: {
			V2BackBtn,
			V2Button
		},
		data: () => ({
		}),
		computed: {
			getCourseId () {
				return this.$route.query.id;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					},
					{
						path: '/browse-courses/checkout-cancelled',
						text: 'Checkout error'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>

p {
  @include font(12, $c-dark-navy, 500, 14px);
  margin:0 0 rem(18);
}
.btn {
  float:left;
  width:auto;
  margin:0 auto 0 0;
}

</style>
